

















































import Vue from 'vue'
import ExchangeService from '@/services/exchange'
import { SelectOption } from '@/models/form'
import { Exchange } from '@/services/exchange/models'
import TerminalCustomSelect from '../../TradesTerminal/components/TerminalFormFilter/TerminalCustomSelect.vue'
import { cloneDeep as _cloneDeep } from 'lodash'
import { SIDE_ORDER } from '@/services/order/models'
import { allExchangeOption } from '../../constants'

export type ParamsFilter = {
  exchange?: SelectOption
  date: string[] | null
  side: string
  entry_price: string
  symbol: string
  status: string
}

const INIT_FILTER = {
  exchange: { ...allExchangeOption },
  date: null,
  side: '',
  entry_price: '',
  symbol: '',
  status: ''
}

export default Vue.extend({
  components: {
    TerminalCustomSelect
  },

  data(): {
    accounts: Exchange[]
    params: ParamsFilter
    entryPrice: string
  } {
    return {
      accounts: [],
      params: _cloneDeep(INIT_FILTER),
      entryPrice: ''
    }
  },

  computed: {
    accountOptions(): SelectOption[] {
      return [{ ...allExchangeOption }].concat(
        this.accounts.map((account) => ({
          label: account.name,
          value: account.name,
          img: `/images/portfolios/${account.name}.svg`
        }))
      )
    },

    SIDES() {
      return SIDE_ORDER
    }
  },

  methods: {
    async getActiveExchanges() {
      try {
        const { data: response } = await ExchangeService.getActiveExchanges()
        if (response.success && response.data && response.data.length > 0) {
          this.accounts = response.data
          const accountSelected = { ...allExchangeOption }
          this.params.exchange = accountSelected
        }
      } catch (_) {}
    },

    reset() {
      this.entryPrice = ''
      this.params = { ..._cloneDeep(INIT_FILTER), exchange: this.params.exchange }
    }
  },

  watch: {
    // entryPrice() {
    //   _debounce(() => {
    //     this.params.entry_price = Number(this.entryPrice)
    //   }, 500)()
    // },

    params: {
      handler(val) {
        this.$emit('on-filter', {
          ...val,
          exchange: val.exchange.value
        })
      },
      deep: true
    }
  },

  created() {
    this.getActiveExchanges()
  }
})
